@charset "utf-8";

/* GLOBAL HEADER
____________________________________________________ */
.global-header {
	position: fixed;
	z-index: 10;
	top: 0;
	left: 0;
	width: 100%;
	background: rgba(var(--color08),1);
	transform: translate3d(0, 0, 0);
	transition: transform 1s cubic-bezier(0.1, 0.57, 0.1, 1);
  margin: 0 auto;
  border-bottom: 1px solid rgba(var(--color01),1);
}
.global-header.fold {
	background: #fff;
	transform: translate3d(0, calc( -1 * var(--gh-H) ), 0);
	transition: transform 0.75s cubic-bezier(0.1, 0.57, 0.1, 1);
}
.global-header-areawrap {
  width: 100%;
}
@media (orientation: landscape) and (max-width: 834px ){
  .global-header-areawrap {
    padding: 0 calc(env(safe-area-inset-left)) 0 calc(env(safe-area-inset-right));
  }
}
.global-header-inner {
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: space-between;
	position: relative;
	background: #fff;
	margin: 0 auto;
	padding: 0;
  width: 100%;
}
@media ( min-width: 834px ) {
	.global-header {
		position: relative;
		transition: none;
    bottom: auto;
	}
	.global-header-inner {
    padding: 13px calc( (100% - var(--base-width)) / 2);
	}
}
@media ( min-width: 1281px ) {
	.global-header-inner {
    padding: 13px calc( (100% - var(--base-width)) / 2);
	}
}

/* while article rich_B */
.article.single .global-header {
	height: 50px;
}
.article.single .global-header-inner {
	padding: 0;
	height: 50px;
}
@media ( min-width: 834px ) {
	.article.single .global-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
  .article.single .global-header-inner {
    min-width: auto;
    margin: 0;
    width: min(80%, 200px);
    padding: 0;
    position: absolute;
    left: 0;
    z-index: 100;
  }
	.article.single .global-header-inner .siteid {
		display: flex;
		justify-content: center;
	}
	.article.single .siteid a {
    width: min(80%, 200px);
	}
}
@media ( min-width: 1281px ) {
	.article.single .global-header {
		justify-content: center;
	}
	.article.single .global-header-inner .siteid {
		width: 100%;
	}
}


.siteid {
   flex-grow: 1;
   padding: 0 0 0 2vw;
}
.siteid a {
	display: block;
	width: 140px;
}
.siteid img {
	width: 100%;
	vertical-align: middle;
	margin-bottom: 3px;
}
@media ( min-width: 834px ) {
	.siteid {
	   padding: 0 0 0 2vw;
	}
  .article.single .siteid {
    padding: 0;
  }
	.siteid a {
		width: 200px;
	}
}
@media ( min-width: 1281px ) {
	.siteid {
	   padding: 0;
	}
}


/* site global sub-function-nav
____________________________________________________ */
.site-function {
	display: flex;
	align-items: center;
	margin-right: 0.5rem;
}
.site-function a {
	color: rgba(var(--color00),1);
}
.site-function .func1,
.site-function .func3,
.site-function .func4,
.site-function .func5 {
  display: none;
}
.site-function > ul {
	display: flex;
}
.site-function > ul [class*="ico-"] {
	font-size: 9px;
	white-space: nowrap;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0 3px;
	font-weight: 700;
}
.site-function > ul [class*="ico-"]::before {
	margin-bottom: 0;
}

.is-subfunc {
	position: relative;
	cursor: pointer;
}
.subfunc {
  opacity: 0;
  visibility: hidden;
	z-index: 100;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	padding: 1.5rem 0 0 0;
	width: -moz-fit-content;
	width: fit-content;
}
.site-function .func4 .subfunc {
  left: auto;
  right: 0;
  transform: none;
}
.site-function .func4 .subfunc[data-func="func-account"] {
  display: none;
}
[class*="login"] .site-function .func4 .subfunc[data-func="func-account"] {
  display: block;
}
.site-function .func5 .subfunc {
  left: auto;
  right: 0;
  transform: none;
}
.subfunc.showSubFunc {
  opacity: 1;
  visibility: visible;
	transition: opacity 0.5s ease;
}

.subfunc > ul {
	position: relative;
	display: flex;
	flex-direction: column;
  width: 100%;
	padding: 1rem;
	border: 1px solid rgba(var(--color01),1);
	border-radius: 7px;
	background: rgba(var(--color08),1);
	box-shadow: 0 6px 10px 0 rgba(var(--color01),0.2);
}
.subfunc > ul::before {
	content: '';
	width: 1rem;
	height: 1rem;
	clip-path: polygon(50% 40%, 0% 100%, 100% 100%);
	position: absolute;
	bottom: 100%;
	left: 50%;
	background: rgba(var(--color01),1);
	transform: translateX(-50%);
}
.site-function .func5 .subfunc > ul::before {
  left: 89%;
}

.subfunc li a {
	display: block;
	color: rgba(var(--color00),1);
	white-space: nowrap;

	padding: 0.75em 0;
	font-size: min(4vw,16px);
}
.subfunc li a:hover {
	color: rgba(var(--color00),0.75);
}

@media ( min-width: 641px ) {
  .site-function .func3 {
    display: none;
  }
  .site-function .func4 {
    display: none;
  }
}
@media ( min-width: 834px ) {
	.site-function > ul {
		padding: 4px 0.75rem;
	}
	.site-function .func1 {
		display: none;
	}
	.site-function .func2 {
		margin-right: 0;
	}
	.site-function .func3 {
		display: flex;
		border-left: 1px solid #ccc;
	}
  .site-function .func4 {
    display: block;
    position: relative;
  }
  .site-function .btn-wrap {
    padding: 0;
  }
	.site-function .btn {
		font-size: min(3.5vw, 13px);
		line-height: 1;
		padding: .9em 1rem;
		font-weight: 700;
		border-radius: 5px;
	}
}
@media ( min-width: 1281px ) {
	.site-function .func1 {
		display: block;
		background: rgba(var(--color04),1);
		border-radius: 2em;
		margin-top: 4px;
	}
	.site-function .func1 .ico-star {
		display: flex;
		align-items: center;
		font-size: min(3.5vw, 10px);
		font-weight: 700;
		color: #fff;
		padding: 0.75em 1.4em;
		white-space: nowrap;
	}
	.site-function .func1 .ico-star::before {
		width: min(3vw, 14px);
		height: min(3vw, 16px);
	}
	.site-function .func2 {
		margin-right: 0;
	}
}
@media (max-width: 374px) {
	.site-function .func4,
	.site-function .subfunc-go {
		display: none
	}
}
@media (max-width: 319px) {
	.site-function .func4,
	.site-function .subfunc-go,
	.site-function .subfunc-mynews {
	display: none
	}
}





/* while article rich_B */
.article.single .global-header-inner .site-function {
	display: none !important;
}

/* while member page */
.member .global-header-inner .site-function .func1,
.member .global-header-inner .site-function .func2,
.member .global-header-inner .site-function .func3,
.member .global-header-inner .site-function .func4 {
  display: none !important;
}
.member .global-header-inner .site-function .func5 {
  display: block;
}
